import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Container from '@components/Container';
import Layout from '@components/Layout';
import Paragraph from '@components/Typography/Paragraph';
import GradientBtn from '@components/Buttons/GradientBtn';
import styled from 'styled-components';
import { DEVICE } from '@assets/const';

const { MD, LG } = DEVICE;

const Heading = styled.p`
  font-size: 1.5rem;
  font-family: 'Cormorant Garamond', serif;
  margin: 0 0 1rem 0;

  @media ${MD} {
    font-size: 2rem;
  }
`;

const Wrapper = styled.div`
  display: block;
  margin: 2rem 0 0 0;
  padding: 0 1rem;

  @media ${MD} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2rem;
    row-gap: 2rem;
    margin: 5rem 0 0 0;
  }
`;

const VerticalLine = styled.div`
  width: 2px;
  background: #bd8604;
  margin: 0 2rem 0 0;
`;

const ArticleWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  margin: 0 0 2rem 0;

  @media ${LG} {
    grid-column-end: 3;
    margin: 0;
  }
`;

const Flex = styled.div`
  display: flex;
`;

const ButtonWrapper = styled.div`
  margin: 2rem 0 0 2rem;
`;

const News = ({ data }) => {
  const { nodes: articles } = data.allMdx;

  const renderArticles = () => {
    return articles.map((item) => {
      const { id, slug } = item;
      const { title, date, shortDescription } = item.frontmatter;

      return (
        <ArticleWrapper key={id}>
          <Flex>
            <VerticalLine />
            <div>
              <Paragraph fsize={0.9} gray>
                <i>{date}</i>
              </Paragraph>
              <Heading>{title}</Heading>
              {shortDescription && (
                <Paragraph justifyMobile gray>
                  {shortDescription}
                </Paragraph>
              )}
            </div>
          </Flex>
          <ButtonWrapper>
            <GradientBtn url={`/aktualnosci/${slug}`} text="Czytaj dalej" />
          </ButtonWrapper>
        </ArticleWrapper>
      );
    });
  };

  return (
    <Layout pageTitle="Aktualności">
      <Container>
        <Wrapper>{renderArticles()}</Wrapper>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___date, order: DESC }, limit: 5) {
      nodes {
        frontmatter {
          date
          title
          shortDescription
        }
        id
        slug
      }
    }
  }
`;

News.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          frontmatter: PropTypes.shape({
            date: PropTypes.string,
            title: PropTypes.string,
            shortDescription: PropTypes.string,
          }),
          id: PropTypes.string,
          slug: PropTypes.string,
        })
      ),
    }),
  }).isRequired,
};

export default memo(News);
